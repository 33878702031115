import './App.scss';
import Header from './components/Header.js';
import Router from './Router';

function App() {
	return (
		<div className="App">
			<Header />

			<Router />
		</div>
	);
}

export default App;
