import PropTypes from 'prop-types';

function TextAndMedia(props) {

	return (
		<div className="TextAndMedia">
			<h3>{props.title}</h3>
			
			{props.text.split('%NEWLINE%').map((i,key) => {
				return <p key={key}>{i}</p>
			})}

			{props.imageSrc && <img src={props.imageSrc} alt={props.imageAlt} />}

			{props.buttons?.length && 
				<div className="buttons-wrapper">
					{props.buttons.map((btn, index) => (
						<a href={btn.url} className={`btn large ${btn.color}`} target={btn.target || '_self'} key={index}>{btn.label}</a>
					))}
				</div>
			}

			{props.children &&
				<div className="children-wrapper">
					{props.children}
				</div>
			}
		</div>
	);
}

TextAndMedia.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	imageSrc: PropTypes.string,
	imageAlt: PropTypes.string,
	buttons: PropTypes.arrayOf(PropTypes.exact({
		label: PropTypes.string,
		url: PropTypes.string,
		target: PropTypes.oneOf(['_blank', '_self']),
		color: PropTypes.oneOf(['green', 'white', 'blue']),
	}))
}

export default TextAndMedia;
