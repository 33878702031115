import {createRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

// Import icons
import {ReactComponent as HomeIcon} from '../assets/images/HomeIcon.svg';
import {ReactComponent as HamburgerIcon} from '../assets/images/hamburgerIcon.svg';

function NavigationBar(props) {
	const navMenu = createRef();
	const toggler = createRef();

	useEffect(() => {
		const jumpToClose = () => {
			navMenu.current.classList.remove('open');
			navMenu.current.querySelectorAll('a').forEach(item => {
				item.setAttribute('tabindex', -1);
			});
		}

		const jumpToOpen = () => {
			navMenu.current.classList.add('open');
			navMenu.current.querySelectorAll('a').forEach(item => {
				item.setAttribute('tabindex', 1);
			});
		}

		const toggleJumpTo = () => {
			if (navMenu.current.classList.contains('open')) {
				jumpToClose();
			} else {
				jumpToOpen();
			}
		}

		if (toggler?.current) {
			toggler.current.addEventListener('click', () => {
				toggleJumpTo();
			});
		}
	}, [navMenu, toggler]);

	return (
		<div
			className={`NavigationBar ${props.theme}`}
		>
			<button
				className="btn back-icon no-icon large nav-go-back"
				onClick={() => window.history.back()}
			>
				Go back
			</button>

			<Link
				to="/"
				className="btn no-icon green large"
			>
				<HomeIcon />
			</Link>

			<button
				className="btn no-icon large nav-jump-to"
				ref={toggler}
			>
				Jump to <HamburgerIcon />

				<nav id="nav-menu" ref={navMenu}>
					<h3>Jump to</h3>

					<ul>
						<li><Link to="/">Start page</Link></li>
						<li><Link to="/product-functionality">Product functionality</Link></li>
						<li><Link to="/product-sustainability">Product sustainability</Link></li>
						<li><Link to="/filling-machines">Filling machines</Link></li>
					</ul>
				</nav>
			</button>
		</div>
	);
}

NavigationBar.propTypes = {
	theme: PropTypes.oneOf(['dark', 'light']),
}

export default NavigationBar;
