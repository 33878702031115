import {useState} from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as VideoIcon} from '../assets/images/VideoIcon.svg';
import VideoOverlay from './VideoOverlay';

function VideoButton(props) {

	const [showVideo, setShowVideo] = useState(false);

    return (
		<div>
			{showVideo && <VideoOverlay videoSrc={props.videoSrc} showOverlay={setShowVideo} /> }

			<button className="VideoButton" onClick={() => setShowVideo(true)}>
				<div className="preview-image">
					<img src={props.previewSrc} alt={props.previewSrcAlt} />
				</div>

				<h4>{props.title}</h4>

				<VideoIcon />
			</button>
		</div>
    );
}

VideoButton.propTypes = {
	title: PropTypes.string,
	videoSrc: PropTypes.string,
	previewSrc: PropTypes.string,
	previewSrcAlt: PropTypes.string,
}

export default VideoButton;
