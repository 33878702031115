import VideoButton from '../components/VideoButton';
import NavigationBar from '../components/NavigationBar';
import Background from '../components/Background';
import TextAndMedia from '../components/TextAndMedia';

// Import content
import AsepticFillingMachine from '../assets/videos/ELOPAK_E-PS120A_filling_machine_short.mp4';
import AsepticLoadingSystem from '../assets/videos/Elopak_automatic_blank_loading_system_for_E-PS120A.mp4';
import ShikokuMachine from '../assets/videos/Elopak_Shikoku_flexible_machine_platform.mp4';
import FillingFreshProducts from '../assets/videos/Elopak_filling_machine_for_fresh_products.mp4';
import FactoryBand from '../assets/images/FactoryBand.svg';

import Img1 from '../assets/images/FM_PPA_2013_EPS120A_004.png';
import Img2 from '../assets/images/FM_ESP-120A_2018_6630.jpg';
import Img3 from '../assets/images/FM_S-PSF70UC_2017.png';
import Img4 from '../assets/images/pic_S-PSF65UC_BOTMAND_1.jpg';

function FillingMachines() {

    return (
        <div className="FillingMachines page light">

			<Background ratio="8/4" colorFirst="slate" colorSecond="white" />

			<div className="row">
				<div className="columns small-12 large-6">
					<TextAndMedia
						title="Filling machines"
						text="Elopak provides efficient and reliable solutions for ambient, fresh and extended shelf life chilled distribution. Our machines come with different production capacities and will fill a wide range of pack sizes and shapes. In addition, machines are adapted and certified to meet various geographical standards.%NEWLINE%Our new aseptic filling line offers maximum flexibility. Pure-Fill, is capable of running both cartons with aluminium and non-aluminium, like the Pure-Pak® eSense carton, at the same time."
						imageSrc={FactoryBand}
					/>
				</div>

				<div className="columns small-12 large-5 large-offset-1">
					<VideoButton videoSrc={AsepticFillingMachine} previewSrc={Img1} previewSrcAlt="Aseptic filling machine" title="Aseptic filling machine" />
					<VideoButton videoSrc={AsepticLoadingSystem} previewSrc={Img2} previewSrcAlt="Aseptic automatic blank loading system" title="Aseptic automatic blank loading system" />
					<VideoButton videoSrc={ShikokuMachine} previewSrc={Img3} previewSrcAlt="Shikoku flexible machine for fresh products" title="Shikoku flexible machine for fresh products" />
					<VideoButton videoSrc={FillingFreshProducts} previewSrc={Img4} previewSrcAlt="Filling machine for fresh products" title="Filling machine for fresh products" />
				</div>
			</div>

			<NavigationBar theme="light" />
        </div>
    );
}

export default FillingMachines;
