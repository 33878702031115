// Import the different pages
import Home from './pages/Home.js';
import ChosenByPeople from './pages/ChosenByPeople.js';
import ProductSustainability from './pages/ProductSustainability.js';
import ProductFunctionality from './pages/ProductFunctionality.js';
import FillingMachines from './pages/FillingMachines.js';

import {
	HashRouter,
	Switch,
	Route,
} from "react-router-dom";

function Router() {
    return (
		<HashRouter>
			<Switch>
				<Route exact path="/chosen-by-people"><ChosenByPeople /></Route>
				<Route exact path="/product-functionality"><ProductFunctionality /></Route>
				<Route exact path="/product-sustainability"><ProductSustainability /></Route>
				<Route exact path="/filling-machines"><FillingMachines /></Route>
				<Route path="/"><Home /></Route>
			</Switch>
		</HashRouter>
    );
}

export default Router;
