import {ReactComponent as Logo} from '../assets/images/logo-white.svg';

function Header() {

    return (
        <header className="Header" id="masthead">
			<div className="center-header">
				<a href="/fair">
					<Logo className="header-logo" />
				</a>
			</div>
        </header>
    );
}

export default Header;
