import PropTypes from 'prop-types';

function Background(props) {
	const ratio = props.ratio.split('/');

    return (
        <div className="Background row">
			<div className={`columns small-${ratio[0]} first ${props.colorFirst}`}></div>
			<div className={`columns small-${ratio[1]} second ${props.colorSecond}`}></div>
        </div>
    );
}

Background.propTypes = {
	ratio: PropTypes.string,
	colorFirst: PropTypes.string,
	colorSecond: PropTypes.string,
}

export default Background;
