// Import external deps
import {useState} from 'react';
import {Link} from 'react-router-dom';

// Import components
import VideoOverlay from '../components/VideoOverlay.js';

// Import SVGs for the hero banner
import {ReactComponent as CloudGroup} from '../assets/images/group-of-clouds.svg';
import {ReactComponent as Singlecloud} from '../assets/images/single-cloud.svg';
import {ReactComponent as Cartons} from '../assets/images/cartons.svg';
import {ReactComponent as StaticMountains} from '../assets/images/StaticMountains.svg';
import {ReactComponent as CartonPour} from '../assets/images/CartonPour.svg';
import {ReactComponent as QRCode} from '../assets/images/qr-code.svg';

// Import content
import History from '../assets/videos/Pure-Pak_100_years_in_100_seconds.mp4';
import Elopak from '../assets/videos/Elopak.mp4';
import ThisIsElopak from '../assets/videos/this_is_elopak-compressed.mp4';

function Home() {
	const [aboutVideo, setAboutVideo] = useState(false);

	return (
		<div className="Home page dark">

			{aboutVideo && <VideoOverlay videoSrc={aboutVideo} showOverlay={setAboutVideo}/> }

			<section className="hero-banner">
				<div className="background-animation-wrapper">
					<CloudGroup />
					<Singlecloud />
					<Cartons />
					<StaticMountains />
					<CartonPour />
				</div>

				<div className="ground">
					<section>
						<div className="button-row">
							<span><button onClick={() => setAboutVideo(History)} href="https://elopak.com" className="btn green video-icon large">Pure-Pak® history</button></span>
							<span><button onClick={() => setAboutVideo(ThisIsElopak)} className="btn green video-icon large">This is Elopak</button></span>
							<span><button onClick={() => setAboutVideo(Elopak)} className="btn green video-icon large">Elopak today</button></span>
							<span><Link to="/product-functionality" className="btn large blue">Product functionality</Link></span>
							<span><Link to="/product-sustainability" className="btn large blue">Product sustainability</Link></span>
							<span><Link to="/filling-machines" className="btn large blue">Filling machines</Link></span>
						</div>
						<div className="qr-code-wrapper">
							<QRCode width="4.5rem" height="4.5rem" />
							<br />
							<span>View on mobile!</span>
						</div>
					</section>
				</div>

				<div className="row">
					<div className="columns small-10 large-10 small-offset-1">
						<h1>Packaging by Nature<sup>®</sup></h1>
					</div>

					<div className="columns-small-12 large-6 large-offset-3">
						<p>Elopak is a leading global supplier of carton packaging and filling equipment. We use renewable, recyclable and sustainably sourced materials to provide innovative packaging solutions. Our iconic Pure-Pak® cartons are designed with the environment, safety and convenience front of mind. They offer a natural and convenient alternative to plastic bottles and fit within a low carbon circular economy.</p>
					</div>
				</div>
			</section>

		</div>
	);
}

export default Home;
