import VideoButton from '../components/VideoButton';
import NavigationBar from '../components/NavigationBar';
import Background from '../components/Background';
import TextAndMedia from '../components/TextAndMedia';

// Import content
import People from '../assets/images/People.svg';
import PurePakESense from '../assets/videos/pure-pak_esense.mp4';
import PurePakMini from '../assets/videos/pure-pak_mini_easy_pop_opening.mp4';
import PurePakFold from '../assets/videos/Pure-Pak_sense_folding_instructions.mp4';
import PurePakOpen from '../assets/videos/Pure-Pak_Classic_easy_opening.mp4';
import TetheredCap from '../assets/videos/Pure-TwistFlip.mp4';
import DPak from '../assets/videos/Elopak_D-PAK_carton.mp4';

import Img1 from '../assets/images/Elopak_Video_Still_TwistFlip.jpg';
import Img2 from '../assets/images/Elopak_MG_4146.jpg';
import Img3 from '../assets/images/Elopak_3519.jpg';
import Img4 from '../assets/images/Elopak_MG_5970.jpg';
import Img5 from '../assets/images/Elopak_Video_Still_ESense.jpg';
import Img6 from '../assets/images/Elopak_Video_Still_Elopak_Mini.jpg';

function ProductFunctionality() {

    return (
        <div className="ProductFunctionality page light">

			<Background ratio="8/4" colorFirst="white" colorSecond="green" />

			<div className="row">
				<div className="columns small-12 large-6">
					<TextAndMedia
						title="Product functionality"
						text="Pure-Pak® cartons are proven best-in-use versus other packaging formats. The optimal combination of materials and shapes makes Pure-Pak® cartons stand out in consumers’ purchasing decisions. We have also introduced our gable top carton for non-food products, the D-PAK™ carton."
						imageSrc={People}
						imageAlt="Alt text"
						buttons={[
							{
								label: 'Read more about our products',
								url: 'https://www.elopak.com/pure-pak-cartons/',
								color: 'blue',
								target: '_blank',
							},
						]}
					/>
				</div>

				<div className="columns small-12 large-5 large-offset-1">
					<VideoButton videoSrc={PurePakESense} previewSrc={Img5} previewSrcAlt="Pure-Pak® eSense" title="Pure-Pak® eSense" />
					<VideoButton videoSrc={PurePakMini} previewSrc={Img6} previewSrcAlt="Pure-Pak® Mini" title="Pure-Pak® Mini" />
					<VideoButton videoSrc={PurePakFold} previewSrc={Img3} previewSrcAlt="How to fold a carton after use" title="How to fold a carton after use" />
					<VideoButton videoSrc={PurePakOpen} previewSrc={Img2} previewSrcAlt="How to open a carton without a screwcap" title="How to open a carton without a screwcap" />
					<VideoButton videoSrc={TetheredCap} previewSrc={Img1} previewSrcAlt="Tethered cap solution" title="Tethered cap solution" />
					<VideoButton videoSrc={DPak} previewSrc={Img4} previewSrcAlt="D-PAK™ cartons for household products" title="D-PAK™ cartons for household products" />
				</div>
			</div>

			<NavigationBar theme="dark" />
        </div>
    );
}

export default ProductFunctionality;
