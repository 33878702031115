import PropTypes from 'prop-types';
import { createRef, useEffect, useState } from 'react';

function VideoOverlay(props) {
	const [playing, setPlaying] = useState(true);
	const [toggler, setToggler] = useState('Pause');
	const video = createRef();

	const toggleVideo = () => {
		setPlaying(!playing)
		if (playing) {
			video.current.pause();
			setToggler('Play');
		} else {
			video.current.play();
			setToggler('Pause');
		}
	}

	useEffect(() => {
		video.current.addEventListener('ended', () => {
			setToggler('Play');
			setPlaying(false);
		})
	}, []);

    return (
        <div className="VideoOverlay">
			<div className="row">
				<div className="columns small-12 large-10 large-offset-1">
					<video
						src={props.videoSrc}
						type="video/mp4"
						autoPlay={true}
						controls={true}
						muted={false}
						ref={video}
					/>

					<div className="buttons-wrapper">
						<button
							className="btn large white no-icon toggle-button"
							onClick={toggleVideo}
						>
							{toggler}
						</button>
						<button
							className="btn large yellow close-icon"
							onClick={() => props.showOverlay(false)}
						>
							Close video
						</button>
					</div>
				</div>
			</div>
        </div>
    );
}

VideoOverlay.propTypes = {
	videoSrc: PropTypes.string,
	showOverlay: PropTypes.func,
}

export default VideoOverlay;
