import NavigationBar from '../components/NavigationBar.js';
import VideoButton from '../components/VideoButton.js';
import Background from '../components/Background.js';

function ChosenByPeople() {
    return (
        <div className="ChosenByPeople page light">

			<Background ratio="4/8" colorFirst="green" colorSecond="white" />

			<div className="row">
				<div className="columns small-12 large-5">
					<span>Image</span>
					<button className="btn yellow large">Our products</button>
					<button className="btn yellow large">Our technologies</button>
				</div>

				<div className="columns small-12 large-6 large-offset-1">
					<h1>Chosen by people</h1>
					<p>Pure-Pak®️ cartons are proven best-in-use versus other packaging formats. The optimal combination of materials and shapes makes Pure-Pak cartons stand out in consumers’ purchasing decisions.</p>

					<VideoButton previewSrc="https://picsum.photos/200" previewSrcAlt="Test alt" title="Easy fold video" />
					<VideoButton previewSrc="https://picsum.photos/200" previewSrcAlt="Test alt" title="Customer prefrence" />
				</div>
			</div>

			<NavigationBar theme="dark" />
        </div>
    );
}

export default ChosenByPeople;
