import VideoButton from '../components/VideoButton';
import NavigationBar from '../components/NavigationBar';
import Background from '../components/Background';
import TextAndMedia from '../components/TextAndMedia';

// Import content
import Balance from '../assets/images/Balance.svg';
import ThisIsCircularity from '../assets/videos/elopak_this_is_circularity_short_version.mp4'
import SustainableForestry from '../assets/videos/Thats_circularity.mp4';
import ResponsibleChoice from '../assets/videos/Make_a_responsible_choice.mp4';
import RenewablePE from '../assets/videos/Pure-Pak_carton_with_renewable_PE.mp4';
import SustainableCarton from '../assets/videos/Pure-Pak_Imagine_from_Elopak.mp4';
import NaturallyPurePak from '../assets/videos/Naturally_Pure-Pak.mp4';
import Img1 from '../assets/images/Elopak_MG_1468.jpg';
import Img2 from '../assets/images/Elopak_MG_4779.jpg';
import Img3 from '../assets/images/DSC03138.jpg';
import Img4 from '../assets/images/Elopak_MG_9740.jpg';
import Img5 from '../assets/images/Elopak_MG_4174.jpg';
import Img6 from '../assets/images/Elopak_Video_Still_Thats_Circularity.jpg';
import CsrLogo from '../assets/images/csr.png';

function ProductSustainability() {
	return (
		<div className="ProductSustainability page dark">

			<Background ratio="8/4" colorFirst="green" colorSecond="white" />

			<div className="row">
				<div className="columns small-12 large-6 sustainability-text-wrapper">
					<TextAndMedia
						title="Product sustainability"
						text="We wish to play a part in the global shift towards a low carbon circular economy. Our Pure-Pak® carton solution is a premium line packaging choice for those who will join us in the mission to replace plastic bottles with fully renewable, low carbon cartons. In 2021 we were awarded a platinum rating for our sustainability performance by EcoVadis, the world’s largest and most trusted provider of business sustainability ratings. Elopak is committed to meeting the new Net-Zero Standard and we have announced science-based emission reduction targets with the aim of becoming a net-zero company by the year 2050."
						imageAlt="Alt text"
						imageSrc={Balance}
						buttons={[
							{
								label: 'Elopak sustainability report',
								url: 'https://sustainabilityreport2021.elopak.com/',
								color: 'blue',
								target: '_blank',
							},
						]}
					/>
					{CsrLogo && <img class="csr-logo" src={CsrLogo} alt="Platinum Top 1% - 2021 EcoVadis Sustainability Rating" />}
				</div>

				<div className="columns small-12 large-5 large-offset-1">
					<VideoButton videoSrc={ThisIsCircularity} previewSrc={Img6} previewSrcAlt="This is circularity" title="This is circularity" />
					<VideoButton videoSrc={SustainableForestry} previewSrc={Img3} previewSrcAlt="Sustainable forestry" title="Sustainable forestry" />
					<VideoButton videoSrc={ResponsibleChoice} previewSrc={Img2} previewSrcAlt="Make responsible choice" title="Make a responsible choice" />
					<VideoButton videoSrc={RenewablePE} previewSrc={Img1} previewSrcAlt="Pure-Pak® with renewable PE" title="Pure-Pak® with renewable PE" />
					<VideoButton videoSrc={NaturallyPurePak} previewSrc={Img5} previewSrcAlt="Naturally Pure-Pak®" title="Naturally Pure-Pak®" />
					<VideoButton videoSrc={SustainableCarton} previewSrc={Img4} previewSrcAlt="Pure-Pak® Imagine- our most sustainable carton" title="Pure-Pak® Imagine- our most sustainable carton" />
				</div>
			</div>

			<NavigationBar theme="light" />
		</div>
	);
}

export default ProductSustainability;
